import { translate as t } from '../translatable/translatable'

const normalizeType = (v: string | number) => {
  if (typeof v === 'number') return v
  return parseInt(v)
}

export const getYearLabel = (year: string | number) => {
  const parsedYear = normalizeType(year)
  let label: string
  if (parsedYear === 1) label = t('rok')
  if (parsedYear === 0 || parsedYear >= 5) label = t('lat')
  if (parsedYear >= 2 && parsedYear < 5) label = t('lata')

  return `${year} ${label}`
}
export const getMonthLabel = (month: string | number) => {
  const parsedMonth = normalizeType(month)
  let label: string
  if (parsedMonth === 1) label = t('miesiąc')
  if (parsedMonth === 0 || parsedMonth >= 5) label = t('miesięcy')
  if (parsedMonth >= 2 && parsedMonth < 5) label = t('miesiące')

  return `${month} ${label}`
}

export const getAgeString = (years: string | number, months: string | number) => {
  const arr = []

  let parsedYear = normalizeType(years)
  let parsedMonth = normalizeType(months)

  if (typeof years === 'string') parsedYear = parseInt(years)

  if (typeof months === 'string') parsedMonth = parseInt(months)

  if (parsedYear > 0) arr.push(getYearLabel(years))
  if (parsedYear > 0 && parsedMonth > 0) arr.push(t('i'))
  if (parsedMonth > 0) arr.push(getMonthLabel(months))

  return arr.join(' ')
}
