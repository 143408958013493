import React, { ChangeEvent, useState } from 'react'
import { useRouter } from 'next/router'
import classNames from 'classnames'
import { Button, Col, Container, Row } from 'reactstrap'

import { translate } from '../../../common/translatable/translatable'
import { Routes } from '../../../routes'
import { CurvyTop } from '../../../common/curvy-top/curvy-top'
import { getAgeString } from '../../../common/formatters/age-formatter'
import { moneyFormatter } from '../../../common/formatters/money-formatter'
import Slider from '../../../common/slider/slider.component'
import { saveDogDataFromCalculator } from '../../plan/plan-storage/plan-storage/save-dog-data-from-calculator'
import { usePlanModal } from '../../../hooks/usePlanModal'

import styles from './slider-calculator.module.scss'

type Props = {
  isLoggedIn: boolean
  previousSectionColor: string
}

const getAgeFactor = (age: number) => {
  if (age <= 12) {
    return 1.1
  }
  if (age > 12 && age <= 6 * 12) {
    return 1
  }

  return 0.9
}

const minimal = 4.5

const priceRanges = [
  {
    weightRange: [1, 2],
    result: 4.5
  },
  {
    weightRange: [3, 4],
    result: 5.6
  },
  {
    weightRange: [5, 6],
    result: 6.3
  },
  {
    weightRange: [7, 8],
    result: 7.6
  },
  {
    weightRange: [9, 10],
    result: 8.8
  },
  {
    weightRange: [11, 15],
    result: 10.2
  },
  {
    weightRange: [16, 19],
    result: 12.4
  },
  {
    weightRange: [20, 23],
    result: 13.3
  },
  {
    weightRange: [24, 28],
    result: 15.2
  },
  {
    weightRange: [29, 36],
    result: 17.2
  },
  {
    weightRange: [37, 40],
    result: 23.5
  },
  {
    weightRange: [41, 50],
    result: 24.5
  }
]

export const calculatePrice = (age: number, weight: number) => {
  const price = priceRanges.find(
    (item) => item.weightRange[0] <= weight && item.weightRange[1] >= weight
  )

  let priceResult = price.result * getAgeFactor(age)

  if (priceResult < minimal) {
    priceResult = minimal
  }

  return `${priceResult.toFixed(1).replace('.', ',')}0`
}

const SliderCalculator: React.FC<Props> = ({ isLoggedIn, previousSectionColor = '#fff' }) => {
  const t = translate

  const router = useRouter()
  const { toggleIsPlanModalOpen } = usePlanModal()

  const [age, setAge] = useState(41)
  const [weight, setWeight] = useState(2)

  const [price, setPrice] = useState(calculatePrice(age, weight))

  const onSliderChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const v = parseInt(e.target.value, 10)
    const whatChanged = e.target.name

    switch (whatChanged) {
      case 'age': {
        const dogData = { weight, age: v }
        setAge(v)
        setPrice(calculatePrice(v, weight))
        saveDogDataFromCalculator(dogData)

        break
      }

      case 'weight': {
        const dogData = { age, weight: v }
        setWeight(v)
        setPrice(calculatePrice(age, v))
        saveDogDataFromCalculator(dogData)

        break
      }

      default:
        console.log('something went wrong')
    }
  }

  const handleButtonClick = () => {
    if (isLoggedIn) {
      router.push(Routes.MyAccount_MyDogs);
    } else {
      toggleIsPlanModalOpen();
    }
  };

  return (
    <div style={{ backgroundColor: previousSectionColor, overflow: 'hidden' }}>
      {/* <div className="curvy-top-green" /> */}
      <div className="bg-dotted-green text-white">
        <CurvyTop fill={previousSectionColor} />
        <Container>
          <Row>
            <Col>
              <div className="text-center text-white mt-50-px">
                <p className="mb-4 h2">{t('Świeże jedzenie w cenie tradycyjnej karmy')}</p>
                <div
                  className="font-weight-500 px-3 font-size-16 font-size-mobile-16"
                  style={{ lineHeight: '24px' }}
                >
                  {t(
                    'Opowiedz nam o swoim psie i sprawdź, ile zapłacisz za świeże jedzenie z dostawą prosto do miseczki.'
                  )}
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <Row>
                <Col xs="12" lg={{ size: 3, order: 1 }} className="push-md-1 col-no-p d-lg-flex">
                  {/*
                   <img
                     src="/svgs/piesek-miska.svg"
                     className="mw-100 mt-5 d-md-none"
                     alt="pies"
                   />
                  */}
                  <div className="d-flex align-items-end">
                    <img
                      src="/svgs/pieski-calc.svg"
                      className="mw-100 mt-5 d-lg-block d-none"
                      alt="pies"
                    />
                  </div>
                </Col>
                <Col xs="12" className="mb-5 mb-lg-0" lg={{ size: 3, offset: 1, order: 3 }}>
                  <img
                    src="/svgs/piesek-miska.svg"
                    className="mt-5 d-lg-none position-absolute w-100"
                    alt="pies"
                    style={{ height: '100%' }}
                  />
                  <img
                    src="/svgs/miska-calc.svg"
                    className="mw-100 position-absolute d-none d-lg-block"
                    style={{ bottom: 0 }}
                    alt="pies"
                  />
                  <div
                    className={classNames([
                      styles.price,
                      'd-flex mr-md-n3 mt-n4 mt-md-n5 justify-content-center align-items-center flex-column font-weight-bold'
                    ])}
                  >
                    <span className="mt-4 font-size-24 font-size-mobile-18" style={{ zIndex: 20 }}>
                      {t('od')}
                    </span>
                    <span
                      className="font-weight-700 font-size-50 font-size-mobile-36"
                      style={{ lineHeight: 1, zIndex: 20 }}
                    >
                      {moneyFormatter(price)}
                    </span>
                    <span className="font-size-24 font-size-mobile-18" style={{ zIndex: 20 }}>
                      {t('dziennie')}
                    </span>
                  </div>
                </Col>
                <Col
                  xs="12"
                  lg={{ size: 4, order: 2 }}
                  className="col-no-p pr-mobile-15-px pl-mobile-15-px mt-3"
                >
                  <div className="font-weight-700 h5 mt-md-3 font-size-20 font-size-mobile-18">
                    <div className="d-flex justify-content-between mt-4 mb-1">
                      <span className="font-weight-700">{t('Mój pies ma:')}</span>
                      <span>{getAgeString(Math.floor(age / 12), age % 12)}</span>
                    </div>
                    <Slider
                      footer
                      name="age"
                      value={age}
                      onChange={onSliderChange}
                      min={7}
                      max={240}
                    />
                    <div className="font-weight-700 d-flex justify-content-between mb-1 pt-3">
                      <span>{t('i waży:')}</span>
                      <span>
                        {weight}
                        &nbsp; kg
                      </span>
                    </div>
                    <Slider
                      footer
                      name="weight"
                      value={weight}
                      onChange={onSliderChange}
                      min={1}
                      max={50}
                    />
                  </div>
                  <div className="text-center pt-3">
                    <Button
                      className="text-white rounded-pill mb-100-px mb-mobile-16-px font-weight-bold mt-25-px font-size-18"
                      color="primary"
                      onClick={handleButtonClick}
                    >
                      <span className="d-md-none">
                        {t('Sprawdź cenę')}
                        &nbsp;
                      </span>
                      <span className="d-none d-md-inline d-lg-inline d-xl-inline">
                        {t('Sprawdź indywidualną cenę')}
                      </span>
                    </Button>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}

export default SliderCalculator
