import React from 'react'

type Props = {
  fill: string
}

export const CurvyTop: React.FC<Props> = ({ fill }) => (
  <>
    <div style={{ height: '100px', width: '100%', overflow: 'hidden' }} className='d-none d-md-block'>
      <svg viewBox="0 0 500 500" preserveAspectRatio="none" height="100%" width="100%">
        <path
          d="M0,500 A500,1500 0 0 1 500,500 v-500 h-500 z"
          style={{ stroke: 'none', fill }}
        ></path>
      </svg>
    </div>
    <div style={{ height: '50px', width: '100%', overflow: 'hidden' }} className='d-md-none'>
      <svg viewBox="0 0 500 500" preserveAspectRatio="none" height="100%" width="100%">
        <path
          d="M0,500 A500,1500 0 0 1 500,500 v-500 h-500 z"
          style={{ stroke: 'none', fill }}
        ></path>
      </svg>
    </div>
  </>
)
