import classNames from 'classnames'
import React from 'react'
import { Input } from 'reactstrap'
import { SliderProps } from './@types/slider.types'
import styles from './slider.module.scss'

const Slider: React.FC<SliderProps> = ({ name, value, onChange, min, max, footer }) => (
  <Input
    name={name}
    type="range"
    value={value}
    onChange={onChange}
    min={min}
    max={max}
    className={classNames([styles.slider, footer ? styles.footerSlider : ''])}
  />
)

export default Slider
