import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import Cookies from 'js-cookie'
import Link from 'next/link'
import React, { useEffect, useState } from 'react'
import { Modal } from 'reactstrap'
import { translate } from 'src/client/common/translatable/translatable'
import { CookieStorage } from 'src/client/modules/plan/plan-storage/@enum/cookie-storage.enum'
import { createLink, Routes } from 'src/client/routes'

import { CookieBarProps } from './@types/cookie-bar.types'
import styles from './cookie-bar.module.scss'

const CookieBar: React.FC<CookieBarProps> = () => {
  const t = translate

  const [show, setShow] = useState(true)

  useEffect(() => {
    const allowCookie = Cookies.get(CookieStorage.ALLOW_COOKIE);

    if (allowCookie) {
      setShow(!allowCookie)
    }
  }, [])

  const handleCloseCookieBar = () => {
    setShow(false)
    Cookies.set(CookieStorage.ALLOW_COOKIE, '1', { path: '/' })
  }

  return (
    <div />
  )

  return (
    show && (
      <Modal isOpen fade={false}>
        <div className="d-flex justify-content-center">
          <div
            className={classNames([
              styles.bar,
              'bg-primary font-weight-600 d-flex justify-content-between'
            ])}
          >
            <img src="/svgs/cookie.svg" className={styles.bar__cookie} alt="pliki cookies" />
            <div className={styles.bar__text}>
              <div>
                {t(
                  'Strona Piesotto.pl używa plików cookies, aby zapewniać treści jak najlepiej dobrane do Twoich potrzeb.'
                )}
              </div>

              <div className="d-flex justify-content-between">
                <div className={styles.bar__link}>
                  <Link href={createLink(Routes.Pages_PrivacyPolicy)}>
                    <span>
                      {t('Dowiedz się więcej ')}
                      <FontAwesomeIcon icon={faArrowRight} width={10} />
                    </span>
                  </Link>
                </div>
              </div>
            </div>

            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <button className={styles.bar__close} id="cookieCloseButton" onClick={handleCloseCookieBar} />
          </div>
        </div>
      </Modal>
    )
  )
}

export default CookieBar
