import { DogCalculatorState } from '../../../../common/@types/plan-storage-state.types'
import { getPlanData } from '../plan-data/get-plan-data'
import { updatePlan } from '../plan-data/update-plan'

export const saveDogDataFromCalculator = async (dogData: DogCalculatorState) => {
  const planStorageData = await getPlanData()

  const updatedPlanData = {
    ...planStorageData,
    dogData
  }

  return updatePlan(updatedPlanData)
}
